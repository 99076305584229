import UserInfo from "./userInfo";

class TeamInfo {
    id: number;
    team_name: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zip_code: string;
    subdomain: string;
    lat: number;
    lon: number;
    email_domain: string;
    website_url: string;
    phone_number: string;
    auto_approve_token: string;
    brand_color: string;
    gc_can_manage_company_info: boolean;
    initials: string;
    users: Array<UserInfo>;
    invited: Array<UserInfo>;
    requested: Array<UserInfo>;
    notification_lists: Array<string>;
    sso_provider: string;
    sso_enforced: boolean;
    sso_provider_data: any;
    has_integration_vendor_link: boolean;

    constructor(team_info) {
        this.id = team_info.id;
        this.team_name = team_info.team_name;
        this.address_1 = team_info.address_1;
        this.address_2 = team_info.address_2;
        this.city = team_info.city;
        this.state = team_info.state;
        this.zip_code = team_info.zip_code;
        this.subdomain = team_info.subdomain;
        this.lat = team_info.lat;
        this.lon = team_info.lon;
        this.email_domain = team_info.email_domain;
        this.website_url = team_info.website_url;
        this.phone_number = team_info.phone_number;
        this.auto_approve_token = team_info.auto_approve_token;
        this.brand_color = team_info.brand_color;
        this.gc_can_manage_company_info = team_info.gc_can_manage_company_info;
        this.initials = team_info.initials;
        this.notification_lists = team_info.notification_lists;

        this.users = team_info.users?.map((user) => new UserInfo(user)) || [];

        this.invited = team_info.invited?.map((user) => new UserInfo(user)) || [];

        this.requested = team_info.requested?.map((user) => new UserInfo(user)) || [];

        this.sso_provider = team_info.sso_provider;
        this.sso_enforced = team_info.sso_enforced;
        this.sso_provider_data = team_info.sso_provider_data;

        this.has_integration_vendor_link = team_info.has_integration_vendor_link;
    }
}

export default TeamInfo;
