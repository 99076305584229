import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { Order } from "../Materials/materialTypes";

export function getCalendarsEventByID(
    team_subdomain: string,
    event_id: string,
    include_project_info: boolean = false
): Promise<any> {
    let url = Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") + "/deliveries/" + event_id;
    if (include_project_info) {
        url += "?include_project_info=true";
    }
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return {};
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return {};
            })
    );
}

export function getCalendarsEventsPageForProject(
    team_subdomain: string,
    project_token: string,
    year: number,
    month: number,
    archived: boolean = false
): Promise<any[]> {
    const url = `
        ${Helper.baseUrlAltSubdomain(
            team_subdomain ? team_subdomain : "projects"
        )}/projects/${project_token}/deliveries?year=${year}&month=${month}&archived=${archived}`;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return [];
            })
    );
}

export function createCalendarEventForProject(
    subdomain: string,
    project_token: string,
    title: string,
    description: string,
    start_date_time: string,
    end_date_time: string,
    tagged_teams: number[],
    tagged_area: string,
    tagged_waypoint: string,
    tagged_equipments: number[],
    tagged_vendor: number | null,
    tagged_vendor_user: number | null,
    orders: Order[],
    is_repeating: boolean = false
): Promise<any> {
    const url = new URL(`${Helper.baseUrlAltSubdomain(subdomain)}/projects/${project_token}/deliveries`);
    if (is_repeating) {
        url.searchParams.append("repeating", "true");
    }

    return trackPromise(
        fetch(url.toString(), {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                event: {
                    title: title,
                    description: description,
                    start_date_time: start_date_time,
                    end_date_time: end_date_time,
                    tagged_teams: tagged_teams,
                    tagged_area: tagged_area,
                    waypoint_uuid: tagged_waypoint,
                    tagged_equipments: tagged_equipments,
                    vendor_id: tagged_vendor,
                    vendor_user_id: tagged_vendor_user,
                    orders: orders,
                },
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function createDoorbellDeliveryEventForProject(
    project_token: string,
    type: string,
    title: string | null,
    description: string | null,
    start_date_time: string,
    end_date_time: string,
    delivery_contact_info: string | null,
    tagged_teams: number[],
    tagged_equipments: number[],
    tagged_area: string | null,
    tagged_waypoint: string | null,
    tagged_vendor: number | null,
    requested_override: boolean
): Promise<object> {
    const url = "/projects/" + project_token + "/schedule_doorbell_delivery";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                event: {
                    type: type,
                    title: title,
                    description: description,
                    start_date_time: start_date_time,
                    end_date_time: end_date_time,
                    delivery_contact_info: delivery_contact_info,
                    tagged_teams: tagged_teams,
                    tagged_equipments: tagged_equipments,
                    tagged_area: tagged_area,
                    tagged_waypoint: tagged_waypoint,
                    tagged_vendor: tagged_vendor,
                    requested_override: requested_override,
                },
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

// TODO: Use a toAPIObject function like materials to update the delivery instead of these individual params
export function updateCalendarEventForProject(
    project_token: string,
    event_unique_token: string,
    title: string,
    description: string,
    start_date_time: string,
    end_date_time: string,
    teams: number[],
    tagged_area: string,
    tagged_waypoint: string,
    tagged_equipments: number[],
    tagged_vendor: number | null | undefined,
    tagged_vendor_user: number | null | undefined,
    arrival_time: string | null,
    departure_time: string | null,
    orders: Order[],
    notificationPreference: boolean = true
): Promise<any> {
    const url = "/projects/" + project_token + "/deliveries/" + event_unique_token;
    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                event: {
                    title: title,
                    description: description,
                    start_date_time: start_date_time,
                    end_date_time: end_date_time,
                    tagged_teams: teams,
                    tagged_area: tagged_area,
                    waypoint_uuid: tagged_waypoint,
                    tagged_equipments: tagged_equipments,
                    vendor_id: tagged_vendor || null,
                    vendor_user_id: tagged_vendor_user || null,
                    arrival_time: arrival_time,
                    departure_time: departure_time,
                    orders: orders,
                    notificationPreference: notificationPreference,
                },
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function updateCalendarEventDeliveryInfoForProject(
    project_token: string,
    event_unique_token: string,
    value
): Promise<object> {
    const url = `/projects/${project_token}/deliveries/${event_unique_token}/active/1`;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ delivery: value }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}
export function rescheduleCalendarEventDeliveryInfoForProject(
    project_token: string,
    event_unique_token: string,
    value
): Promise<object> {
    const url = `/projects/${project_token}/deliveries/${event_unique_token}/driver_info/1`;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ event: value }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function confirmCalendarEventForProject(
    project_token: string,
    event_unique_token: string,
    travel_time: number | null,
    driver_contact: string | null
): Promise<object> {
    const url = `/projects/${project_token}/deliveries/${event_unique_token}/driver_info/1`;
    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ travel_time: travel_time, driver_contact: driver_contact }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function approveCalendarEventForProject(
    project_token: string,
    event_unique_token: string,
    approval_id: number,
    approval_value: boolean
): Promise<object> {
    const url = `/projects/${project_token}/deliveries/${event_unique_token}/approval/${approval_id}`;
    const options = {
        method: "put",
        body: JSON.stringify({ approval_value: approval_value }),
    };
    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function finalApproveCalendarEventForProject(
    project_token: string,
    event_unique_token: string,
    approval_id: number,
    final_approval_value: boolean
): Promise<object> {
    const url = `/projects/${project_token}/deliveries/${event_unique_token}/approval/${approval_id}`;
    const options = {
        method: "put",
        body: JSON.stringify({ final_approval_value: final_approval_value }),
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export function getReceivingTeamsListingForDelivery(
    project_token: string,
    receiving_teams: any[],
    tagged_vendor: number
): Promise<{
    leads_by_teams: { team: any; leads: any[] }[];
    contacts_by_vendor: { vendor_name: string; contact_info: string };
}> {
    const url = "/projects/" + project_token + "/deliveries/notifications_info";
    return trackPromise(
        fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                receiving_teams: receiving_teams,
                tagged_vendor: tagged_vendor,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("Failed to put");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getProjectUpcomingEvents(
    project_token: string,
    current_date_time: string,
    count: number
): Promise<any[]> {
    const url =
        "/projects/" +
        project_token +
        "/upcoming_events?current_date_time=" +
        encodeURIComponent(current_date_time.toString()) +
        "&count=" +
        count;
    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return [];
            })
    );
}

export function archiveProjectCalendarEvent(project_token: string, delivery_token: string): Promise<object> {
    const url = `/projects/${project_token}/deliveries/${delivery_token}`;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }

                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function declineLocationForDeliveries(project_token: string, old_location: string) {
    const url = `/projects/${project_token}/deliveries/locations/${old_location}`;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function reassignLocationsForDeliveries(project_token: string, old_location: string, new_location: string) {
    const url = `/projects/${project_token}/deliveries/locations/${old_location}`;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                new_location: new_location,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getPendingDeliveries(team_subdomain: string, project_token: string, date: Date): Promise<any[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/deliveries/active";
    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                check_date: date,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return [];
            })
    );
}

/**
 *
 * @param project_token Unique token of the project to ring the doorbell of
 * @param values Values data (arrival_type, eta_value)
 * @returns boolean indicating success
 */
export function createUnscheduledDelivery(project_token: string, values): Promise<boolean> {
    const url = "/projects/" + project_token + "/deliveries/unscheduled";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ring_data: values }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function sendOverviewEmail(project) {
    const url =
        Helper.baseUrlAltSubdomain(project.claimed_by_team_subdomain) +
        "/projects/" +
        project.unique_token +
        "/delivery_overview_email_send";

    return trackPromise(
        fetch(url, {
            method: "post",
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}
