import TeamInfo from "../../teamInfo";
import { Equipment } from "../Equipment/equipmentTypes";
import { Order } from "../Materials/materialTypes";

export class DateType {
    type: number = 0;
    typeName: string = "";

    static YEAR: DateType = new DateType(0, "Year");
    static MONTH: DateType = new DateType(1, "Month");
    static WEEK: DateType = new DateType(2, "Week");
    static DAY: DateType = new DateType(3, "Daily");
    static LIST: DateType = new DateType(4, "List");
    static MAP: DateType = new DateType(5, "Map");

    constructor(type: number, typeName: string) {
        this.type = type;
        this.typeName = typeName;
    }

    static fromID(id: number): DateType {
        if (id == this.YEAR.type) {
            return this.YEAR;
        } else if (id == this.MONTH.type) {
            return this.MONTH;
        } else if (id == this.WEEK.type) {
            return this.WEEK;
        } else if (id == this.DAY.type) {
            return this.DAY;
        } else if (id == this.LIST.type) {
            return this.LIST;
        } else if (id == this.MAP.type) {
            return this.MAP;
        }

        return this.WEEK;
    }

    getName(): string {
        return this.typeName;
    }
}

export class EventStatus {
    type: number = 0;
    typeName: string = "";

    static DECLINED: EventStatus = new EventStatus(-100, "declined");
    static REQUESTED_OVERRIDE: EventStatus = new EventStatus(0, "requested_override");
    static PENDING: EventStatus = new EventStatus(50, "pending");
    static ORDERED: EventStatus = new EventStatus(75, "ordered");
    static SCHEDULED: EventStatus = new EventStatus(100, "scheduled");
    static UPDATED: EventStatus = new EventStatus(200, "updated");
    static CONFIRMED: EventStatus = new EventStatus(300, "confirmed");
    static EN_ROUTE: EventStatus = new EventStatus(400, "en_route");
    static ARRIVED: EventStatus = new EventStatus(500, "arrived");
    static DEPARTED: EventStatus = new EventStatus(600, "departed");

    constructor(type: number, typeName: string) {
        this.type = type;
        this.typeName = typeName;
    }

    static allStates(): { name: string; value: number }[] {
        const states = new Array<{ name: string; value: number }>();

        states.push({ name: "Declined", value: EventStatus.DECLINED.type });
        states.push({ name: "Requested Override", value: EventStatus.REQUESTED_OVERRIDE.type });
        states.push({ name: "Pending", value: EventStatus.PENDING.type });
        states.push({ name: "Ordered", value: EventStatus.ORDERED.type });
        states.push({ name: "Scheduled", value: EventStatus.SCHEDULED.type });
        states.push({ name: "Updated", value: EventStatus.UPDATED.type });
        states.push({ name: "Confirmed", value: EventStatus.CONFIRMED.type });
        states.push({ name: "En Route", value: EventStatus.EN_ROUTE.type });
        states.push({ name: "Arrived", value: EventStatus.ARRIVED.type });
        states.push({ name: "Departed", value: EventStatus.DEPARTED.type });

        return states;
    }

    static fromNumber(status: number): EventStatus {
        if (status === this.DECLINED.type) {
            return this.DECLINED;
        } else if (status === this.REQUESTED_OVERRIDE.type) {
            return this.REQUESTED_OVERRIDE;
        } else if (status === this.PENDING.type) {
            return this.PENDING;
        } else if (status === this.ORDERED.type) {
            return this.ORDERED;
        } else if (status === this.SCHEDULED.type) {
            return this.SCHEDULED;
        } else if (status === this.UPDATED.type) {
            return this.UPDATED;
        } else if (status === this.CONFIRMED.type) {
            return this.CONFIRMED;
        } else if (status === this.EN_ROUTE.type) {
            return this.EN_ROUTE;
        } else if (status === this.ARRIVED.type) {
            return this.ARRIVED;
        } else if (status === this.DEPARTED.type) {
            return this.DEPARTED;
        }

        return this.PENDING;
    }

    static fromString(status: string): EventStatus {
        if (status === this.DECLINED.typeName) {
            return this.DECLINED;
        } else if (status === this.REQUESTED_OVERRIDE.typeName) {
            return this.REQUESTED_OVERRIDE;
        } else if (status === this.PENDING.typeName) {
            return this.PENDING;
        } else if (status === this.ORDERED.typeName) {
            return this.ORDERED;
        } else if (status === this.SCHEDULED.typeName) {
            return this.SCHEDULED;
        } else if (status === this.UPDATED.typeName) {
            return this.UPDATED;
        } else if (status === this.CONFIRMED.typeName) {
            return this.CONFIRMED;
        } else if (status === this.EN_ROUTE.typeName) {
            return this.EN_ROUTE;
        } else if (status === this.ARRIVED.typeName) {
            return this.ARRIVED;
        } else if (status === this.DEPARTED.typeName) {
            return this.DEPARTED;
        }

        return this.PENDING;
    }

    isAtLeast(compare: EventStatus) {
        return this.type >= compare.type;
    }

    isMoreThan(compare: EventStatus) {
        return this.type > compare.type;
    }

    isAtMost(compare: EventStatus) {
        return this.type <= compare.type;
    }

    isLessThan(compare: EventStatus) {
        return this.type < compare.type;
    }

    equals(compare: EventStatus) {
        return this.type === compare.type;
    }
}

export class EventAttachment {
    id: number;
    key: string;
    filename: string;
    content_type: string;
    metadata: any;
    service_name: string;
    byte_size: number;
    checksum: string;
    created_at: string;
    attachable_sgid: string;
}

export class CalendarEvent {
    // DB Basics
    id: number;
    unique_token: string;
    project: any;

    // Basic Info
    title: string;
    description: string;
    status: EventStatus;
    created_by: any;
    requested_override: boolean;
    creation_location: number;
    final_approved: boolean;

    // Time Info
    start: Date;
    end: Date;
    arrival_time: Date | null;
    departure_time: Date | null;
    eta: Date | null;

    // Area Info
    drop_off_area_uuid: string;
    drop_off_area: any;
    waypoint_uuid: string;
    waypoint: any;

    // Receiving Teams
    teams: any[];
    delivery_has_teams: any[];
    receiving_team: any;
    receiving_team_leads: any[];

    // Vendors
    vendor: TeamInfo | null;
    vendor_user: any | null;
    vendor_directions: { routes: { duration: number; distance: number }[] } | null;

    // Equipment
    equipment: Equipment[];
    vehicle_type: string;

    // Attachments (images, files, etc.)
    attachments: EventAttachment[];

    // Lead times connection
    orders: Order[];

    constructor() {
        this.attachments = [];
    }

    static fromAPIObject(event: any): CalendarEvent {
        const delivery = new CalendarEvent();

        // DB Basics
        delivery.id = event.id;
        delivery.unique_token = event.unique_token;
        delivery.project = event.project;

        // Basic Info
        delivery.title = event.title;
        delivery.description = event.description;

        delivery.status = EventStatus.fromString(event.status);

        delivery.created_by = event.created_by;
        delivery.requested_override = event.requested_override;
        delivery.creation_location = event.creation_location;
        delivery.final_approved = event.final_approved;

        // Time info
        delivery.start = new Date(event.start_date_time);
        delivery.end = new Date(event.end_date_time);
        delivery.arrival_time = event.arrival_time ? new Date(event.arrival_time) : null;
        delivery.departure_time = event.departure_time ? new Date(event.departure_time) : null;
        delivery.eta = event.eta ? new Date(event.eta) : null;

        // Area Info
        delivery.drop_off_area_uuid = event.drop_off_area_uuid;
        delivery.drop_off_area = event.drop_off_area;
        delivery.waypoint_uuid = event.waypoint_uuid;
        delivery.waypoint = event.waypoint;

        // Receiving teams
        delivery.teams = event.teams;
        delivery.delivery_has_teams = event.delivery_has_teams;
        delivery.receiving_team =
            event.teams.length == 1
                ? event.teams[0]
                : event.teams.find((team) => team.id !== event.project.owning_team_id);

        // Vendors
        delivery.receiving_team_leads = event.receiving_team_leads;
        delivery.vendor = event.vendor ? new TeamInfo(event.vendor) : null;
        delivery.vendor_user = event.vendor_user;
        delivery.vendor_directions = event.vendor_directions ? JSON.parse(event.vendor_directions) : null;

        // Equipment
        delivery.equipment = event.equipment
            ? event.equipment.map((equipment) => Equipment.fromAPIObject(equipment))
            : [Equipment.fromAPIObject({ id: -1 })];
        delivery.vehicle_type = event.vehicle_type;

        // Attachments (Images, files etc)
        delivery.attachments = event.blobs;

        // Lead times connection
        delivery.orders = event.orders;

        return delivery;
    }

    asDataObject(project_teams: any[], project_areas: any[]) {
        const team_names = new Array<any>();
        for (const team of this.teams) {
            for (const project_team of project_teams) {
                if (team.id == project_team.id) {
                    team_names.push(project_team.team_name);
                    break;
                }
            }
        }

        let tagged_area_name = "";
        for (const area of project_areas) {
            if (this.drop_off_area_uuid === area.id) {
                tagged_area_name = area.properties.plot.area_name;
                break;
            }
        }

        let tagged_waypoint_name = "";
        for (const area of project_areas) {
            if (this.waypoint_uuid === area.id) {
                tagged_waypoint_name = area.properties.plot.area_name;
                break;
            }
        }

        const equipment_names = new Array<string>();
        for (const equipment_piece of this.equipment) {
            equipment_names.push(equipment_piece.name);
        }

        return {
            id: this.id,
            unique_token: this.unique_token,
            start: this.start.toISOString(),
            end: this.end.toISOString(),
            start_text:
                this.start.toLocaleDateString("en-US", { month: "numeric", day: "numeric", year: "numeric" }) +
                this.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true }),
            end_text:
                this.end.toLocaleDateString("en-US", { month: "numeric", day: "numeric", year: "numeric" }) +
                this.end.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true }),
            title: this.title,
            description: this.description,
            teams: team_names,
            delivery_has_teams: this.delivery_has_teams,
            receiving_team: this.receiving_team,
            receiving_team_leads: this.receiving_team_leads,
            tagged_area: tagged_area_name,
            tagged_waypoint: tagged_waypoint_name,
            equipment: equipment_names,
            vehicle_type: this.vehicle_type,
            vendor_id: this.vendor?.id,
            vendor_user_id: this.vendor_user?.id,
            eta: this.eta ? this.eta.toISOString() : null,
            status: this.status.typeName,
            arrival_time: this.arrival_time ? this.arrival_time.toISOString() : null,
            departure_time: this.departure_time ? this.departure_time.toISOString() : null,
            requested_override: this.requested_override,
            creation_location:
                this.creation_location === 0 ? "DOORBELL" : this.creation_location === 1 ? "DELIVERY TAB" : "UNKNOWN",
            created_by: this.created_by ? this.created_by.id : null,
        };
    }
}
