import * as Activity from "./components/PlotAPI/Activity";
import * as Calendar from "./components/PlotAPI/Calendar";
import * as Comments from "./components/PlotAPI/Comments";
import * as DeliveryAttachments from "./components/PlotAPI/DeliveryAttachments";
import * as DeliveryAvailabilities from "./components/PlotAPI/DeliveryAvailabilities";
import * as ExceptionDays from "./components/PlotAPI/ExceptionDays";
import * as FireTV from "./components/PlotAPI/FireTV";
import * as Integrations from "./components/PlotAPI/Integrations";
import * as Invite from "./components/PlotAPI/Invite";
import * as Login from "./components/PlotAPI/Login";
import * as MagicToken from "./components/PlotAPI/MagicToken";
import * as Map from "./components/PlotAPI/Map";
import * as Material from "./components/PlotAPI/Material";
import * as Notifications from "./components/PlotAPI/Notifications";
import * as Order from "./components/PlotAPI/Order";
import * as Procurement from "./components/PlotAPI/Procurement";
import * as Project from "./components/PlotAPI/Project";
import * as ProjectAttachments from "./components/PlotAPI/ProjectAttachments";
import * as Submittal from "./components/PlotAPI/Submittal";
import * as Team from "./components/PlotAPI/Team";
import * as Trade from "./components/PlotAPI/Trade";
import * as User from "./components/PlotAPI/User";
import * as Vendor from "./components/PlotAPI/Vendor";
import * as Weather from "./components/PlotAPI/Weather";

const PlotAPI = {
    ...Activity,
    ...Calendar,
    ...Comments,
    ...DeliveryAttachments,
    ...DeliveryAvailabilities,
    ...ExceptionDays,
    ...FireTV,
    ...Integrations,
    ...Invite,
    ...Login,
    ...MagicToken,
    ...Map,
    ...Material,
    ...Notifications,
    ...Order,
    ...Procurement,
    ...Project,
    ...ProjectAttachments,
    ...Submittal,
    ...Team,
    ...Trade,
    ...User,
    ...Vendor,
    ...Weather,
};

export default PlotAPI;
